
.animation {
    position: fixed;
    bottom: 0;
    animation-name: animation;
    animation-duration: 2s;
}

@keyframes animation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.textBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /*background: rgba(22, 22, 23, 0.9);*/
    /*border: 1px solid #2b2933;*/
    border-radius: 5px;
    position: fixed;
    bottom: 24px;
    right: 50%;
    transform: translate(50%, 0);

    min-width: 100px;
    max-width: 847px;
    width: 100%;
    word-break: break-word;
    /*max-height: 104px;*/
    font-family: "Montserrat", sans-serif;
    width: 100%;
    /*width: 848px;*/
    /*height: 104px;*/
    /*background: linear-gradient(110deg, #FF01C0, #02A7FF) border-box;*/
    /*-webkit-clip-path: polygon(0% 50%, 6% 1%, 94% 1%, 100% 50%, 94% 99%, 6% 99%);*/
    /*clip-path: polygon(0% 50%, 6% 1%, 94% 1%, 100% 50%, 94% 99%, 6% 99%);*/
    /*border-radius: 5px;*/
    background: url("../../../assets/svg/clip-path.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    min-height: 104px;
}


.textBlock p {
    margin: 0;
    padding: 14px 52px;
    font-weight: 500;
    font-size: clamp(1rem, 0rem + 1.25vw, 2rem);
    line-height: 1.2;

    /*position: absolute;*/
    /*top: 5px;*/
    /*left: 5px;*/
    /*right: 5px;*/
    /*bottom: 5px;*/
    color: #FFFFFF;
    /*background: #2b2933;*/
    /*-webkit-clip-path: polygon(0.5% 50%, 6% 0%, 94% 0%, 99.5% 50%, 94% 100%, 6% 100%);*/
    /*clip-path: polygon(0.5% 50%, 6% 0%, 94% 0%, 99.5% 50%, 94% 100%, 6% 100%);*/
}

.participants {
    padding: 22px 24px;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.participants::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    border: 5px solid transparent;
    background: linear-gradient(110deg, #FF01C0, #02A7FF) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0, transparent 0) padding-box, linear-gradient(#fff 0, transparent 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
}

.participants .title {
    text-align: center;
    font-weight: 400;
    font-size: clamp(1.625rem, 0rem + 2.0313vw, 3.25rem);
    line-height: 1.2;
    color: white;
    margin-bottom: 23px;
}

.participantBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.participantItem {
    font-weight: 300;
    font-size: clamp(1rem, 0rem + 1.25vw, 2rem);
    line-height: 1.2;
    color: white;
}

.participantItem:nth-child(1),
.participantItem:nth-child(2),
.participantItem:nth-child(3) {
    font-weight: 400;
}

.participantItem:nth-child(1) .points,
.participantItem:nth-child(2) .points,
.participantItem:nth-child(3) .points {
    font-weight: 700;
    display: flex;
    align-items: center;
}

.participantItem:nth-child(1) .points:before {
    content: '';
    margin-right: 22px;
    width: 24px;
    height: 24px;
    background-image: url('../../../assets/svg/trophyGold.svg');
}

.participantItem:nth-child(2) .points:before {
    content: '';
    margin-right: 22px;
    width: 24px;
    height: 24px;
    background-image: url('../../../assets/svg/trophySilver.svg');
}

.participantItem:nth-child(3) .points:before {
    content: '';
    margin-right: 22px;
    width: 24px;
    height: 24px;
    background-image: url('../../../assets/svg/trophyBronze.svg');
}

.participants .participant {
    display: flex;
    gap: clamp(0.5rem, 0rem + 0.625vw, 1rem);
    align-items: center;
}

.avatar {
    width: clamp(1.5rem, 0rem + 1.875vw, 3rem);
    height: clamp(1.5rem, 0rem + 1.875vw, 3rem);
}

.avatar img {
    border-radius: 50%;
    height: 100%;
    vertical-align: unset;
    object-fit: cover;
}

.participantName {
    width: 100%;;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

}

.imgBox {
    position: fixed;
    bottom: 24px;
    left: 12px;

    /*width: fit-content;*/
    /*height: fit-content;*/
    /*min-height: calc(100% - 50vh);*/
    /*min-width: 30vw;*/
    /*max-width: calc(100% - 50vw);*/
    /*max-height: calc(100% - 50vh);*/
    /*object-fit: contain;*/
    /*display: flex;*/

}

.imgBox::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    border: 5px solid transparent;
    background: linear-gradient(110deg, #FF01C0, #02A7FF) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0, transparent 0) padding-box, linear-gradient(#fff 0, transparent 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
}

.imgBox img {
    object-fit: contain;
    border-radius: 20px;
    min-width: 35vw;
    max-width: 45vw;
    max-height: 80vh;
}
.stats {
    padding: 33px 42px 24px 33px;
    background: rgba(22, 22, 23, 0.9);
    border-radius: 10px;
    color: white;
    min-width: 329px;
    position: fixed;
    right: 12px;
    top: 12px;
    z-index: 2;

}

.stats::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    border: 5px solid transparent;
    background: linear-gradient(110deg, #FF01C0, #02A7FF) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0, transparent 0) padding-box, linear-gradient(#fff 0, transparent 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
}

.stats .title {
    color: white;
    font-weight: 500;
    font-size: clamp(1rem, 0rem + 1.25vw, 2rem);
    line-height: 24px;
    margin-bottom: clamp(0.8125rem, 0.0625rem + 0.9375vw, 1.5625rem);;
}

.stats .text {
    font-weight: 400;
    font-size: clamp(0.875rem, 0rem + 1.0938vw, 1.75rem);
    line-height: 24px;
    display: flex;
    flex-direction: column;
    gap: clamp(0.5rem, 0.0625rem + 0.5469vw, 0.9375rem);;
}

.stats .text div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.stats .text ul {
    padding-left: 20px;
}
