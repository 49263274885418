.token {
    padding-top: 9px;
    color: #828282;
    font-size: 12px;
    font-weight: 600;
}

.buttonSave {
    position: relative;
}

.changeColor {
    display: flex;
}

.linkForChangeColor {
    margin-left: 5px;
    color: #0419b1;
    text-decoration: underline;
    cursor: pointer;
}

.linkForChangeColor:hover {
    color: #1565c0
}

.colorForSpan {
    color: #828282;
}

.showColor {
    margin-top: 11px;
    display: flex;
}

.numberOfColor {
    margin-left: 15px;
    font-size: 16px;
    font-weight: bolder;
}

.hr {
    justify-content: flex-start;
    left: -21px;
    width: 100%;
    color: #828282;
    size: 1px;
}

.picker {
    padding: 15px 12px 15px 0;
}

.position {
    position: relative;
}

.solid {
    top: -20px;
    right: 0;
    background: #FFF;
    position: absolute;
    width: 68px;
    height: 50px;
}

.stylesForTextField {
    position: relative;
}

.stylesForTextField+.stylesForTextField {
    margin-top: 20px
}
.dataPickerLabel {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px !important;
    font-weight: 400;
    line-height: 24px;
}
.forPicture {
    margin-top: 24px;
    margin-left: 22px;
    width: 24px;
    height: 24px;
    opacity: 0.9;
    cursor: pointer
}


@media all and (min-width: 0px) and (max-width: 1480px) {
    .forPicture {
        margin-left: 0;
    }
}