@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');


@media screen and (max-height: 800px) {
    body {
        overflow: auto;
    }
}

.App {
    font-family: "Montserrat", sans-serif;
    line-height: 1.2;
    font-weight: 400;
    min-height: 100vh;
    position: relative;
    min-width: 1200px;
}

.Main {
    display: flex;
    border-top: 1px solid #DFDFDF;

}

Header {
    padding: 6px 24px 6px;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
    font-family: "Montserrat-SemiBold", sans-serif !important;
}


.css-j4llg4-MuiPaper-root-MuiAlert-root {
    z-index: 9999;
}

.css-1e0yc0d {
    width: 278px;
    z-index: 900;
}

main {
    position: relative;
    min-height: calc(100vh - 92px);
}

.questions-scroll-block {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: clamp(34.375rem, 18.75rem + 19.5313vw, 50rem);
}

.alert-enter {
    opacity: 0;
    transform: scale(0.9);
}

.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.quizTypeBtn {
    border-radius: 2px !important;
}

.quizTypeBtn:last-child {
    margin-left: 30px !important;
    border-radius: 2px !important;
}

.quizTypeBtn:last-child::before {
    content: '';
}

.zoom-block-portal {
    /*position: fixed;*/
    /*width: 73%;*/
    /*height: calc(100vh - 90px);*/
    /*top: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    /*bottom: 0;*/
    /*margin: 24px auto;*/
    /*overflow: auto;*/
    /*z-index: 99;*/


    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 99999;
    transform: translate(-50%, -50%);
    /*height: 65vh;*/
}


.zoom-img-portal {
    /*height: 100%;*/
    max-height: 80vh;
    max-width: 80vw;
    object-fit: contain;
}

.zoom-out-portal {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    z-index: 999;
}



