.spinnerContainer {
    position: absolute;
    width: 100%;
    overflow: hidden;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-Index: 90;
}
